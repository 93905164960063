.bodyDivWeb {
    display: block;
    width: 100vw;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 75px;
}
 
.title {
    font-size: 30px;
    color: #F4B366;
    font-family: 'Raleway';
    font-weight: bold;
    margin-top: 20px;
}

.titleSmall {
    font-size: 24px;
    color: white;
    font-family: 'Raleway';
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 20px;
}

.divSeparatorCon {
    display: flex;
}

.divLeft {
    width: 50%;
    height: 70vh;
}

.line {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.lineMulti {
    display: flex;
}

.iconContacts {
    color: #F4B366;
    height: 25px;
}

.iconReclama {
    height: 45px;
    margin-left: 20px;
    margin-top: -12px;
}

.iconRSociais {
    height: 35px;
    margin-right: 20px;
}

.text {
    font-size: 22px;
    margin-left: 20px;
    font-family: 'Raleway';
}

.divRight {
    width: 50%;
    height: 80vh;
}

.inputs {
    width: 80%;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid grey;
    color: grey;
    font-family: 'Raleway';
}

.inputBarsMultiline {
    width: 80%;
    background-color: transparent;
    border: none;
    color: grey;
    border: 1px solid grey;
    border-radius: 3px;
    margin-top: 10px;
    font-family: 'Raleway';
}

.linecenter {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sendButton {
    height: 60px;
    width: 250px;
    border-radius: 3px;
    font-size: 18px;
    font-family: 'Raleway';
    font-weight: bold;
    background-color: #F4B366;
}

.iconSent {
    color: green;
    padding-left: 20px;
}

.divFooter {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.textFooter {
    color: gray;
    font-size: 12px;
}


/*---- mobile ----*/
.bodyDiv-mobile {
    display: none;
    width: 100vw;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 70px;
}

.title-mobile {
    font-size: 30px;
    color: #F4B366;
    font-family: 'Raleway';
    font-weight: bold;
    margin-top: 30px;
}

.titleSmall-mobile {
    font-size: 26px;
    color: white;
    font-family: 'Raleway';
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 20px;
}

.divTop {
    width: 90%;
}

.line-mobile {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.text-mobile {
    color: white;
    font-size: 20px;
    margin-left: 20px;
    font-family: 'Raleway';
}

.divBot {
    width: 100%;
    height: 600px;
}

.inputs-mobile {
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid grey;
    color: grey;
    font-family: 'Raleway';
}

.inputBarsMultiline-mobile {
    width: 100%;
    background-color: transparent;
    border: none;
    color: grey;
    border: 1px solid grey;
    border-radius: 3px;
    margin-top: 10px;
    font-family: 'Raleway';
}

.linecenter-mobile {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 600px) {
    .bodyDivWeb {
        display: none;
    }

    .bodyDiv-mobile {
        display: block;
    }
}