.App {
  background-color: rgba(26, 19, 46, 1);
  height: 100vh;
  width: 100vw;
}

.backImage {
  width: 100%;
  height: 70vh;
}

.text {
  color:azure;
}

.loadingContainer {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingText {
  color: white;
  font-family: 'Raleway';
}

@media screen and (max-width: 600px) {
  .backImage {
    height: 35vh;
  }
}