/*behaviour on larger screens*/
/*---------------- nav bar start ----------------*/
.header-back {
    display: flex;
    flex-direction: row;
    background-color: rgba(26, 19, 46, 0.6);
    height: 60px;
    width: 100vw;
    position: fixed;
    z-index: 1;
    box-shadow: 0px 1px 4px #F4B366;
}

/*mobile menu icon div*/
.iconMenu-div {
    display: none;
    background: none;
    padding-left: 5%;
    width: 60px;
}

/*menu icon*/
.menu-button {
    font-size:30px;
    cursor: pointer;
    background: none;
    border: none;
    color: white;
    margin-top: 5px;
}

/*logo div*/
.logoMenu-div {
    background: none;
    margin-left: 5%;
    margin-top: 5px;
    cursor: pointer;
}

/*logo ER*/
.logo {
    width: 100px;
    height: 45px;
    margin: 0;
    padding: 0;
}

/*icon language div*/
.icon-div {
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 5%;
    margin-top: 10px;
}

/*icon language div mobile*/
.icon-div-mobile {
    display: none;
    flex-direction: row;
    position: absolute;
    right: 5%;
    margin-top: 10px;
}

/*icon language*/
.icon {
    height: 35px;
    margin-right: 10px;
}

/*icon language label*/
.iconLabel {
    color: white;
    margin-top: 7px;
    font-size: 18px;
    font-family: 'Raleway';
    cursor: pointer;
}

.iconLabel-selected {
    color: #F4B366;
}
/*---------------- nav bar end ----------------*/




/*---------------- menu items start ----------------*/
.menuDiv {
    margin-left: 5%;
    display: flex;
    flex-direction: row;
    margin-top: 12px;
}

.menuOptions {
    color: white;
    position: relative;
    margin-left: 40px;
    font-family: 'Raleway';
    font-size: 18px;
    font-weight: bold;
    text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.5);
    text-decoration: none;
}

.menuOptions:hover {
    color: #F4B366;
    cursor: pointer;
}

.menuOptions-selected {
    color: #F4B366;
}

.mobile-menu {
    display: none;
}

.mobile-menu.open {
    transform: translateX(0);
    height: 100vh;
    width: 60vw;
  }

.mobile-menuOptions {
    color: white;
    margin-left: 15%;
    font-size: 26px;
    font-family: 'Raleway';
    text-decoration: none;
}

.mobile-menuOptions-selected {
    color: #F4B366;
}
/*---------------- menu items end ----------------*/



/*----------------small screens behaviour ----------------*/
@media screen and (max-width: 600px) {
    .menu-button {
      display: inline-block;
    }
  
    .mobile-menu {
      display: block;
      background-color: rgb(26, 19, 46);
      position: absolute;
      margin-top: 62px;
      transition: transform 0.1s ease;
      transform: translateX(-50vw);
      border-right: 1px solid #F4B366;
      padding-top: 30px;
    }

    .menuDiv {
        display: none;
    }

    .iconMenu-div {
        display: block;
    }

    .iconLabel {
        display: none;
    }

    .icon-div-mobile {
        display: flex;
    }
  }