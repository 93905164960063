body {
    margin: 0;
    padding: 0;
    background-color: rgba(26, 19, 46, 1);
}

.bigDiv {
    padding: 5%;
    height: 100vh;
    width: 100vw;
}

.bigTitle1 {
    font-family: 'Raleway';
    color: #F4B366;
    font-weight: bold;
    font-size: 50px;    
}

.smallTitle1 {
    font-family: 'Raleway';
    color: white;
    font-size: 30px;   
}

.logoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}

.logo {
    width: 45%;
    height: 85%;
}

@media screen and (max-width: 600px) {
    .logo {
        width: 85%;
        height: 85%;
        margin-top: 20%;
        margin-bottom: 20%;
    }

    .bigTitle1 {
        font-size: 40px;  
    }
}