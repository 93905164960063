body {
  font-size: 20px;
  overflow-x: hidden;
}

.backImage {
  width: 100vw;
  height: 550px;
  background-image: url('../Images/fundoNatal.png');
}

.title1 {
  font-size: 270%;
  color: white;
  text-shadow: 2px 2px #1A132E;
  font-family: 'Raleway';
  font-weight: bold;
  margin-top: 100px;
  margin-left: 10%;
}

.title2 {
  font-size: 150%;
  color: #F4B366;
  text-shadow: 2px 2px #1A132E;
  font-family: 'Raleway';
  font-weight: bold;
  margin-left: 10%;
}

.cardDiv {
  position: absolute;
  height: 60vh;
  width: 100%;
  margin: 0;
}

.centered-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10%;
}

.yellowButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F4B366;
  color: #1A132E;
  border: none;
  border-radius: 5px;
  box-shadow: 0 2px 4px #1E1E1E;
  font-size: 18px;
  font-family: 'Raleway';
  font-weight: bolder;
  text-decoration: none;
  -webkit-text-stroke: #1A132E;
  -webkit-text-stroke-width: 1px;
  cursor: pointer;
  height: 60px;
  width: 250px;
}

.centeredDiv {
  padding-left: 10%;
  padding-right: 10%;
  color: white;
  font-family: 'Raleway';
  height: 450px;
}

.regText {
  font-weight: bold;
  font-size: 26px;
}

.regTextYellow {
  font-weight: bold;
  font-size: 24px;
  color: #F4B366;
}

.challengeCard {
  border: 1px solid #F4B366;
  border-radius: 3px;
  height: 350px;
  margin-left: 7%;
  margin-right: 7%;
  margin-top: 40px;
  display: flex;
}

.challengeCardLeft {
  width: 40%;
}

.challengeCardRight {
  width: 60%;
  display: block;
  justify-content: center;
  align-items: center;
  font-family: 'Raleway';
}

.divClick {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10%;
}

.yellowCorner {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F4B366;
  color: #1A132E;
  border: none;
  border-radius: 2px;
  font-size: 18px;
  font-family: 'Raleway';
  cursor: pointer;
  height: 50px;
  width: 20%;
}

.cardImage {
  height: 99%;
  width: 100%;
  cursor: pointer;
}

.divPrices {
  display: flex;
  justify-content: right;
}

.divTitle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.div3icons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconStyle {
  color:#F4B366;
  height: 30px;
  margin-right: 5px;
}

.yellowButtonSmall {
  margin-top: 30px;
  background-color: #F4B366;
  color: #1A132E;
  border: none;
  border-radius: 3px;
  box-shadow: 0 2px 4px #1E1E1E;
  font-size: 18px;
  font-family: 'Raleway';
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  height: 50px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.textSeparator {
  margin-right: 50px;
  margin-left: 5px;
  font-size: 24px;
  margin-top: 6%;
}

.marginLefttext {
  margin-left: 20%;
}

.textoRiscado {
  text-decoration: line-through;
  color: white;
}

/*mobile cards*/
.mobileSeparator {
  display: none;
}

.challengeCard-mobile {
  display: none;
  justify-content: center;
  align-items: center;
  border: 1px solid #F4B366;
  border-radius: 3px;
  height: 300px;
  padding: 5%;
}

.challengeCardUnique{
  display: block;
  justify-content: center;
  align-items: center;
  font-family: 'Raleway';
}

.div3icons-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
}

.divTitle-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
}

.divTabs {
  display: flex;
  margin-top: 20px;
  height: 35px;
  width: 100%;
}

.divSeparator {
  border-top: 1px solid #F4B366;
  border-left: 1px solid #F4B366;
  border-right: 1px solid #F4B366;
  border-radius: 0px 5px 2px 3px;
  height: 100%;
  width: 32%;
  justify-content: center;
  font-size: 18px;
}

.divSeparatorAbas {
  border-top: 1px solid #F4B366;
  border-left: 1px solid #F4B366;
  border-right: 1px solid #F4B366;
  border-radius: 0px 5px 2px 3px;
  height: 100%;
  width: 32%;
  justify-content: center;
  font-size: 18px;
  padding-left: 5px;
}

@media screen and (max-width: 600px) {
  .backImage {
    height: 40vh;
    background-image: url('../Images/cardNatal.png');
  }

  .title1 {
    font-size: 140%;
    margin-top: 20%;
  }

  .title2 {
    font-size: 80%;
    font-weight: normal;
    margin-top: -5px;
  }

  .yellowButton {
   height: 40px;
   padding: 10px 15px;
   font-weight: normal;
   font-size: 14px;
  }

  .centered-button {
    height: 40px;
    margin-top: 10%;
  }

  .challengeCard {
    display: none;
  }

  .challengeCard-mobile {
    display: flex;
  }

  .cardImage {
    display: none;
  }

  .yellowButtonSmall {
    margin-bottom: 20px;
  }

  .centeredDiv {
    height: 400px;
  }

  .mobileSeparator {
    display: block;
  }

  .cardDiv {
    height: 40vh;
    width: 100%;
  }

  .marginLefttext {
    margin-left: 5%;
    font-size: 16px;
  }
}