.bodyDiv {
    display: block;
    width: 100vw;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 75px;
}

.Title1 {
    color: #F4B366;
    font-family: 'Raleway';
    font-size: 30px;
    font-weight: bold;
}

.Title2 {
    font-family: 'Raleway';
    font-size: 22px;
    color: white;
}

.Title3 {
    font-family: 'Raleway';
    font-size: 20px;
    color: white;
}

.miniLetters {
    font-family: 'Raleway';
    font-size: 16px;
    color: white;
}

.textAvisos {
    color: white;
    font-family: 'Raleway';
}

/*------- bookings form 1 --------*/
.formDiv1 {
    display: flex;
    margin-top: 5%;
}

/*------- left side is titles and separation bars --------*/
.formDiv1-left {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    width: 20%;
    padding-top: 10px;
    border-right: 3px solid #F4B366;
    color: #F4B366;
    font-family: 'Raleway';
    font-size: 26px;
}


/*------- right side is content --------*/
/* -- div sizes -- */
.formDiv1-right {
    height: 70px;
    width: 80%;
    display: flex;
    align-items: center;
    padding-left: 50px;
}

.formDiv1-mobile {
    display: none;
}

/* -- selection cards -- */
.formCardChallenges {
    height: 60px;
    width: 170px;
    border: 1px solid white;
    border-radius: 3px;
    font-size: 18px;
    font-family: 'Raleway';
    background-color: transparent;
    color: white;
    font-weight: bold;
}

.formCardChallenges-selected {
    background-color: #F4B366;
    color: #1A132E;
}

/*------- bookings form 2 --------*/
.formDiv2 {
    display: flex;
    margin-top: 5%;
}

/*------- left side is titles and separation bars --------*/
.formDiv2-left {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 320px;
    width: 20%;
    padding-top: 10px;
    border-right: 3px solid #F4B366;
    color: #F4B366;
    font-family: 'Raleway';
    font-size: 26px;
}


/*------- right side is content --------*/
/* -- div sizes -- */
.formDiv2-right {
    height: 320px;
    width: 80%;
    display: flex;
    align-items: center;
    padding-left: 50px;
}

.formDiv2-right1 {
    height: 300px;
    width: 40%;
    margin-right: 5px;
}

.formDiv2-right2 {
    height: 300px;
    width: 50%;
}

.cardHour {
    display: inline-block;
    height: 45px;
    width: 100px;
    background-color: transparent;
    margin-left: 15px;
    color: white;
    text-align: center;
    font-family: 'Raleway';
    margin-bottom: 15px;
    padding-top: 5px;
    border-radius: 20px;
    border: 1px solid white;
}

.cardHour-selected {
    background-color: #F4B366;
    color: black;
    display: inline-block;
    height: 45px;
    width: 100px;
    margin-left: 15px;
    text-align: center;
    font-family: 'Raleway';
    margin-bottom: 15px;
    padding-top: 5px;
    border-radius: 20px;
    border: 1px solid white;
}

/*------- bookings form 3 --------*/
.formDiv3 {
    display: flex;
    margin-top: 5%;
}

/*------- left side is titles and separation bars --------*/
.formDiv3-left {
    display: block;
    justify-content: center;
    align-items: center;
    height: 320px;
    width: 20%;
    padding-top: 30px;
    border-right: 3px solid #F4B366;
    color: #F4B366;
    font-family: 'Raleway';
    font-size: 26px;
}


/*------- right side is content --------*/
/* -- div sizes -- */
.formDiv3-right {
    height: 320px;
    width: 80%;
    display: block;
    align-items: center;
    padding-left: 50px;
    padding-top: 20px;
}

.formElementDiv {
    margin-bottom: 20px;
}

.formElementDivGrouped {
    display: flex;
    margin-bottom: 20px;
}

.formElementDivGroupedvertical {
    margin-right: 80px;
}

.inputBars {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid grey;
    color: grey;
    margin-left: 10px;
    width: 500px;
    font-family: 'Raleway';
}

.inputBarsSmall {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid grey;
    color: grey;
    margin-left: 10px;
    margin-right: 25px;
    width: 200px;
    font-family: 'Raleway';
}

.error {
    color: red;
    font-size: 14px;
}

.select {
    background-color: transparent;
    border: 1px solid #F4B366;
    border-radius: 3px;
    color: white;
    font-family: 'Raleway';
    height: 40px;
    width: 200px;
    padding-left: 10px;
}

.option {
    background-color: #1A132E;
    color: white;
}

/*------- bookings form 4 --------*/
.formDiv4 {
    display: flex;
    margin-top: 5%;
    margin-bottom: 5%;
}

/*------- left side is titles and separation bars --------*/
.formDiv4-left {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 20%;
    padding-top: 10px;
    border-right: 3px solid #F4B366;
    color: #F4B366;
    font-family: 'Raleway';
    font-size: 26px;
}


/*------- right side is content --------*/
/* -- div sizes -- */
.formDiv4-right {
    height: 200px;
    width: 80%;
    display: flex;
    align-items: center;
    padding-left: 50px;
}

.cardTotal {
    height: 150px;
    width: 200px;
    background-color: transparent;
    border: 2px solid #F4B366;
    border-radius: 5px;
    margin-right: 30px;
}

.totalNum {
    color: #F4B366;
    font-size: 34px;
    font-family: 'Raleway';
    margin-left: 35%;
}

.continueButton {
    height: 60px;
    width: 20%;
    border: 1px solid white;
    border-radius: 3px;
    font-size: 18px;
    font-family: 'Raleway';
    font-weight: bold;
    cursor: not-allowed;
}

.continueButton-selected {
    background-color: #F4B366;
    color: #1A132E;
    cursor: pointer;
}

.continueButton:disabled {
    cursor: not-allowed;
}

.breakDiv {
    height: 20px;
}

.endCard {
    border: 2px solid #F4B366;
    height: 50vh;
    color: white;
    font-family: 'Raleway';
    display: grid;
    place-items: center;
    margin-top: 5%;
    margin-bottom: 50px;
}

.buttonDivLine {
    display: flex;
}

.validateButton {
    height: 60px;
    width: 170px;
    border: 1px solid white;
    background-color: #F4B366;
    color: #1A132E;
    border-radius: 3px;
    font-size: 18px;
    font-family: 'Raleway';
    font-weight: bold;
    cursor: not-allowed;
    margin-left: 50px;
}

.lastDiv {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.backButton {
    height: 50px;
    width: 170px;
    border: 1px solid white;
    border-radius: 3px;
    font-size: 18px;
    font-family: 'Raleway';
    font-weight: bold;
    margin-left: 50px;
    color:#1A132E;
}

.backButton-selected {
    background-color: #F4B366;
    color: #1A132E;
    cursor: pointer;
}

.backButton:disabled {
    cursor: not-allowed;
}

/*------- bookings form confirmation div --------*/

.confirmationCard {
    transition: transform 5s ease-in-out, opacity 5s ease-in-out;
    transform: translateX(0);
    opacity: 1;
}

.confirmationCard.hidden {
    transform: translateX(-100%);
    opacity: 0;
  }

.formDivConfirmation-left {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    padding-top: 10px;
    border-right: 3px solid #F4B366;
    color: #F4B366;
    font-family: 'Raleway';
    font-size: 26px;
}

.formDivConfirmation-right {
    width: 80%;
    display: block;
    align-items: center;
    padding-left: 50px;
}

.buttonDivLine-confirmation {
    display: flex;
    margin-top: 20px;
}


.formDivConfirmation {
    display: flex;
    margin-top: 5%;
    margin-bottom: 5%;
}

.confirmationCardTitle {
    color: #F4B366;
    font-family: 'Raleway';
}

.confirmationCardText {
    color: white;
    margin-left: 10px;
    font-family: 'Raleway';
}

.goButton {
    height: 50px;
    width: 170px;
    border: 1px solid white;
    background-color: #F4B366;
    border-radius: 3px;
    font-size: 18px;
    font-family: 'Raleway';
    font-weight: bold;
    cursor: not-allowed;
    color: #1A132E;
    margin-left: 130px;
}

.hiddenicon {
    display: none;
    margin-left: 5px;
}

.goButton:hover .hiddenicon {
    display: inline-block;
}

.goButton:hover {
    box-shadow: 1px 1px 5px grey;
}



/*------- mobile --------*/
/*------- bookings 1 --------*/
.formDiv1-mobile {
    display: none;
}

.formDiv1-mobile-top {
    width: 80vw;
    height: 50px;
    padding-top: 10px;
    border-bottom: 2px solid #F4B366;
    color: #F4B366;
    font-family: 'Raleway';
    font-size: 26px;
}

.formDiv1-mobile-bottom {
    height: 70px;
    width: 80vw;
    padding-top: 10px;
}

/*------- bookings 2 --------*/
.formDiv2-mobile {
    display: none;
}

.formDiv2-mobile-top {
    width: 80vw;
    height: 50px;
    padding-top: 10px;
    margin-top: 20px;
    border-bottom: 2px solid #F4B366;
    color: #F4B366;
    font-family: 'Raleway';
    font-size: 26px;
}

.formDiv2-mobile-bottom {
    width: 80vw;
    padding-top: 10px;
}

.formDiv2-mobile-right1 {
    height: 300px;
}

.formDiv2-mobile-right2 {
    display: grid;
}

/*------- bookings 3 --------*/
.formDiv3-mobile {
    display: none;
}

.formDiv3-mobile-top {
    width: 80vw;
    padding-top: 10px;
    margin-top: 20px;
    border-bottom: 2px solid #F4B366;
    color: #F4B366;
    font-family: 'Raleway';
    font-size: 26px;
}

.formDiv3-mobile-bottom {
    height: 480px;
    width: 80vw;
    padding-top: 20px;
}

.formElementDiv-mobile {
    margin-bottom: 20px;
}

.formElementDivGrouped-mobile {
    display: block;
    margin-bottom: 20px;
}

.formElementDivGroupedvertical-mobile {
    margin-right: 80px;
    margin-bottom: 20px;
}

.inputBars-mobile {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid grey;
    color: grey;
    font-family: 'Raleway';
    width: 100%;
}

.inputBarsSmall {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid grey;
    color: grey;
    margin-left: 10px;
    margin-right: 25px;
    width: 200px;
    font-family: 'Raleway';
}

/*------- bookings 4 --------*/
.formDiv4-mobile {
    display: none;
}

.formDiv4-mobile-top {
    width: 80vw;
    height: 50px;
    padding-top: 10px;
    margin-top: 20px;
    border-bottom: 2px solid #F4B366;
    color: #F4B366;
    font-family: 'Raleway';
    font-size: 26px;
}

.formDiv4-mobile-bottom {
    height: 400px;
    width: 80vw;
    padding-top: 10px;
}

.cardTotal-mobile {
    height: 150px;
    width: 80vw;
    background-color: transparent;
    border: 2px solid #F4B366;
    border-radius: 5px;
    margin-right: 30px;
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.continueButton-mobile {
    height: 60px;
    width: 100vw;
    border: 1px solid white;
    border-radius: 3px;
    font-size: 18px;
    font-family: 'Raleway';
    font-weight: bold;
    cursor: not-allowed;
}

.continueButton-mobile-selected {
    background-color: #F4B366;
    color: #1A132E;
    cursor: pointer;
}

.continueButton-mobile:disabled {
    cursor: not-allowed;
}

.lastDiv-mobile {
    display: none;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

/*------- small screens behaviour --------*/
@media screen and (max-width: 600px) {
    .formDiv1-mobile {
        display: block;
    }

    .formDiv2-mobile {
        display: block;
    }

    .cardHour {
        margin-left: 5px;
    }

    .cardHour-selected {
        margin-left: 5px;
    }

    .formDiv3-mobile {
        display: block;
    }

    .formDiv4-mobile {
        display: block;
    }

    .formDiv1 {
        display: none;
    }

    .formDiv2 {
        display: none;
    }

    .formDiv3 {
        display: none;
    }

    .formDiv4 {
        display: none;
    }

    .Title1 {
        font-size: 25px;
    }

    .Title2 {
        text-align: justify;
        font-size: 15px;
    }

    .miniLetters {
        text-align: justify;
    }

    .endCard {
        padding: 5%;
        text-align: center;
    }

    .lastDiv {
        display: none;
    }

    .lastDiv-mobile {
        display: flex;
    }

    .formDivConfirmation {
        display: block;
        margin-top: 5%;
        margin-bottom: 5%;
    }

    .formDivConfirmation-left {
        width: 100%;
        padding-top: 10px;
        color: #F4B366;
        font-family: 'Raleway';
        font-size: 24px;
        border-bottom: 2px solid #F4B366;
        border-right: none;
        margin-bottom: 20px;
    }

    .formDivConfirmation-right {
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .buttonDivLine-confirmation {
        display: block;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .backButton {
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .goButton {
        width: 100%;
        padding: 0;
        margin-top: 30px;
        margin-left: 0;
    }
}