.backImage2 {
    width: 100vw;
    height: 350px;
}

.divCenteredContent {
    padding-left: 10%;
    padding-right: 10%;
}

.divBigTitle {
    display: flex;
    align-items: center;
    justify-content: center;
}

.BigTitle {
    color: white;
    font-family: 'Raleway';
    font-weight: bold;
    font-size: 34px;
}

.regularText {
    color: white;
    font-family: 'Raleway';
    font-size: 22px;
    margin-top: 40px;
}

.regularTextYellow {
    color: #F4B366;
    font-family: 'Raleway';
    font-size: 22px;
    margin-top: 40px;
}

.regularTextSmall {
    color: white;
    font-family: 'Raleway';
    font-size: 16px;
    margin-top: 45px;
}

.div3Texts {
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 40px;
    margin-bottom: 40px;
}

.textos3 {
    color: #F4B366;
    font-family: 'Raleway';
    padding-left: 10%;
    padding-right: 10%;
}

.divAssinatura {
    padding-left: 40%;
    padding-right: 40%;
    margin-top: 50px;
    color: white;
    font-family: 'Raleway';
    height: 200px;
}

.logoImage {
    width: 250px;
    height: 120px;
}

@media screen and (max-width: 600px) {
    .backImage2 {
        width: 100vw;
        height: 230px;
    }

    .div3Texts {
        display: block;
        padding-left: 5%;
        padding-right: 5%;
    }

    .textos3 {
        padding: 0;
    }

    .divAssinatura {
        height: 240px;
        padding-left: 10%;
        padding-right: 10%;
    }

    .regularText {
        text-align: justify;
    }

    .BigTitle {
        font-size: 24px;
    }

    .regularText {
        font-size: 18px;
        margin-top: 30px;
    }
}