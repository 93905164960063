.stepsDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 100px;
}

.step {
    display: block;
    align-items: center;
    justify-content: center;
    width: 33%;
    margin-left: 3%;
    margin-right: 3%;
    font-family: 'Raleway';
}

.stepCircle {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.circle-active {
    border: 2px solid white;
    background-color: #F4B366;
    border-radius: 20px;
    height: 40px;
    width: 40px;
    padding-left: 13px;
    padding-top: 2px;
    color: #1A132E;
    font-family: 'Raleway';
}

.circle {
    border: 2px solid white;
    border-radius: 20px;
    height: 40px;
    width: 40px;
    padding-left: 13px;
    padding-top: 2px;
    font-family: 'Raleway';
}

.stepText {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: 'Raleway';
}

.mainDiv {
    padding-left: 20%;
    padding-right: 20%;
    margin-top: 30px;
}

.buttonDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}

.imageCard {
    height: 350px;
    width: 350px;
}

.gobackButton {
    height: 50px;
    width: 170px;
    border: 1px solid white;
    border-radius: 3px;
    font-size: 18px;
    font-family: 'Raleway';
    font-weight: bold;
    margin-left: 50px;
    color:#1A132E;
}

.goButtonWeb {
    height: 60px;
    width: 170px;
    border: 1px solid white;
    background-color: #F4B366;
    color: #1A132E;
    border-radius: 3px;
    font-size: 18px;
    font-family: 'Raleway';
    font-weight: bold;
    cursor: not-allowed;
    margin-left: 50px;
}

.hiddeniconBtn {
    display: none;
    margin-left: 5px;
}

.goButtonWeb:hover .hiddeniconBtn {
    display: inline-block;
}

.goButtonWeb:hover {
    box-shadow: 1px 1px 5px grey;
}

.divVoucherLeft {
    width: 50%;
}

.divVoucherRight {
    width: 50%;
    padding-left: 20px;
    color: white;
    font-family: 'Raleway';
}

.divCentrado {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: 'Raleway';
}

.textSimple {
    color: white;
    font-family: 'Raleway';
}

.textSimpleYellow {
    color: #F4B366;
    font-family: 'Raleway';
}

.inputLine {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid grey;
    color: grey;
    margin-left: 10px;
    width: 500px;
    font-family: 'Raleway';
}

.errorStar {
    color: red;
    font-size: 14px;
}

@media screen and (max-width: 600px) {
    .stepsDiv {
        padding-left: 5%;
        padding-right: 5%;
    }

    .stepText {
        font-size: 16px;
    }

    .imageCard {
        height: 250px;
        width: 250px;
    }

    .divVoucherLeft {
        width: 100%;
    }
    
    .divVoucherRight {
        width: 100%;
        text-align: justify;
    }

    .divCentrado {
        display: block;
    }

    .goButtonWeb {
        height: 60px;
        width: 100%;
        margin: 0px;
        padding: 0px;
    }

    .gobackButton {
        height: 50px;
        width: 100%;
        margin-bottom: 10px;
        margin-left: 0;
        padding: 0px;
    }

    .mainDiv {
        padding-left: 10%;
        padding-right: 10%;
        margin-top: 30px;
    }

    .buttonDiv {
        display: block;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        padding-bottom: 30px;
    }

    .inputLine {
        margin-left: 0;
        width: 100%;
    }
}